import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { JobbOfferBaseInfo } from "~/models/JobbOfferBaseInfo";
import {
  RecruitmentTeamMember,
  JobOfferRequirementProfileToAdd,
  TrelloBoardTalent,
  TrelloBoardLaneBase,
  RecruitmentListItem,
  TeamComment,
  CommentSender,
  OpportunitySourcing,
  TalentMessage,
  TalentProfileToView,
  TalentAnonymous,
  TrelloBoardActiveTabType,
  GetSharedTalentDto,
  LaneType,
  RequirementGroupDto,
  LangObject,
  InviteUserRequestDto,
  RecruitmentFilterItem,
  RecruitmentSortOptions,
  RecruitmentFilterOptions,
  RecruitmentLogObject,
  ThingToLoad,
  SkillRecommendation,
  WantMoreCandidatesTip,
  UpsertDeclineWantMoreTipDto,
  SearchTagResultDto,
} from "~/models/types";
import SortHelper from "~/helpers/sortHelper";
import { notificationsStore, jobbOfferStore } from "~/store";
import { $msal } from "~/utils/msal";
import jobOfferService, {
  GetFeaturedJobsDto,
  GetSearchTagCodesForRecruitmentParams,
  getAllSearchTags,
  getFeaturedJobs,
  getSearchTagCacheKey,
  getSearchTagCodesForRecruitment,
  retakeTalent,
  setHasReachedRecapDate,
} from "~/services/jobOfferService";
import TalentStatusHelpers from "~/helpers/talentStatusHelpers";
import {
  authStore,
  companyBaseStore,
  jobbOfferVisualStore,
  lanesStore,
  officesStore,
  talentPoolStore,
} from "~/utils/store-accessor";
import TalentHelpers from "~/helpers/talentHelpers";
import { $i18n } from "~/utils/i18n";
import TokenHelpers from "~/helpers/tokenHelpers";
import SignalRHelper from "~/helpers/SignalRHelper";
import LookupDataService from "~/services/lookupDataService";
import { ListObject } from "~/models/ListObject";
import FilterHelpers from "~/helpers/filterHelpers";
import TalentPoolHelpers from "~/helpers/TalentPoolHelpers";
import RecruitmentTalentService from "~/services/RecruitmentTalentService";
import TalentProfileService from "~/services/talentProfileService";
import { WorkFromHome } from "~/models/WorkFromHome";
import { getTeamMembersForRecruitment } from "~/helpers/RecruitmentHelperFunctions";
import {
  getWantMoreCandidatesTips,
  upsertDeclineWantMoreTip,
} from "~/services/companyService";
import { tipIsEqual } from "~/helpers/RobotHelpers";
import { getThingOrNull } from "~/helpers/CommonHelperFunctions";

const defaultRequirements: JobOfferRequirementProfileToAdd = {
  baseSalary: 0,
  variableCompensation: null,
  skills: [],
  taskIds: [],
  responsibilities: [],
  roleIds: [],
  yearsOfWorkExperience: null,
  maxYearsOfWorkExperience: null,
  branchIds: [],
  educationLevel: null,
  schools: [],
  languageIds: [],
  educationDomainIds: [],
  branches: [],
};

export type LoadedRecTalentListItem = {
  talentId: string;
  recruitmentId: string;
} & ({ type: "Loading" } | { type: "Loaded"; recTalent: TrelloBoardTalent });

export type LoadedTalentProfileListItem =
  | { type: "Loading" }
  | { type: "Loaded"; talentProfile: TalentProfileToView | TalentAnonymous };

@Module({
  name: "jobbOffer",
  stateFactory: true,
  namespaced: true,
})
export default class JobbOffer extends VuexModule {
  id: string | null = null;
  baseInfo: JobbOfferBaseInfo | null = null;
  requirementsLoaded: string | null = null;
  companyMembers: RecruitmentTeamMember[] = [];
  requirementGroupIds: RequirementGroupDto[] = [];
  compareShowDifferences: boolean = false;
  compareOpenedPanels: number[] = [0];
  compareFilteredSkills: string[] = [];
  compareFilteredTaskIds: string[] = [];
  compareStartIndex: number = 0;
  jobbOfferRequirementsCurrent: JobOfferRequirementProfileToAdd = defaultRequirements;
  opportunitySourcing: JobOfferRequirementProfileToAdd = {
    baseSalary: 0,
    variableCompensation: null,
    skills: [],
    taskIds: [],
    responsibilities: [],
    roleIds: [],
    yearsOfWorkExperience: null,
    maxYearsOfWorkExperience: null,
    branchIds: [],
    educationLevel: null,
    schools: [],
    languageIds: [],
    educationDomainIds: [],
    branches: [],
  };

  skillRecommendationsByRoleId: Record<
    string,
    ThingToLoad<SkillRecommendation[]>
  > = {};

  @Mutation
  SET_SKILL_RECOMMENDATIONS_BY_ROLE_ID(v: {
    roleId: string;
    recommendations: ThingToLoad<SkillRecommendation[]>;
  }) {
    this.skillRecommendationsByRoleId = {
      ...this.skillRecommendationsByRoleId,
      [v.roleId]: v.recommendations,
    };
  }

  featuredJobs: ThingToLoad<GetFeaturedJobsDto[]> = {
    type: "notFetched",
  };

  removedFeaturedJobs: GetFeaturedJobsDto[] = [];

  @Mutation
  REMOVE_FEATURED_JOB(v: GetFeaturedJobsDto) {
    this.removedFeaturedJobs = [...this.removedFeaturedJobs, v];
  }

  get featuredJobsList(): GetFeaturedJobsDto[] {
    return (getThingOrNull(this.featuredJobs) ?? [])
      .filter(
        x =>
          !this.removedFeaturedJobs.some(
            r => r.areaName === x.areaName && r.title.id === x.title.id
          )
      )
      .slice(0, 3);
  }

  @Mutation
  SET_FEATURED_JOBS(v: ThingToLoad<GetFeaturedJobsDto[]>) {
    this.featuredJobs = v;
  }

  @Action
  async loadFeaturedJobs(v: { force: boolean }) {
    const resultFromLocal = TokenHelpers.getThingToLoadOrExit(
      this.featuredJobs,
      v.force
    );

    if (resultFromLocal.type === "exit") {
      return;
    }

    jobbOfferStore.SET_FEATURED_JOBS(resultFromLocal);

    const token = await TokenHelpers.getToken();
    const result = await getFeaturedJobs({
      token,
    });

    jobbOfferStore.SET_FEATURED_JOBS({
      type: "loaded",
      value: result,
    });
  }

  allSearchTags: ThingToLoad<SearchTagResultDto[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_ALL_SEARCH_TAGS(v: ThingToLoad<SearchTagResultDto[]>) {
    this.allSearchTags = v;
  }

  @Action
  async loadAllSearchTags() {
    if (this.allSearchTags.type === "notFetched") {
      jobbOfferStore.SET_ALL_SEARCH_TAGS({
        type: "loading",
      });

      const token = await TokenHelpers.getToken();
      const result = await getAllSearchTags(token);
      jobbOfferStore.SET_ALL_SEARCH_TAGS({
        type: "loaded",
        value: result,
      });
    }
  }

  searchTagsByParams: Record<string, ThingToLoad<SearchTagResultDto[]>> = {};

  @Mutation
  SET_SEARCH_TAGS_BY_PARAMS(v: {
    params: string;
    tags: ThingToLoad<SearchTagResultDto[]>;
  }) {
    this.searchTagsByParams = {
      ...this.searchTagsByParams,
      [v.params]: v.tags,
    };
  }

  @Action
  async loadSearchTagsByParams(v: {
    params: GetSearchTagCodesForRecruitmentParams;
    force: boolean;
  }) {
    const cacheKey = getSearchTagCacheKey(v.params);

    const resultFromLocal = TokenHelpers.getThingToLoadOrExit(
      this.searchTagsByParams[cacheKey] ?? { type: "notFetched" },
      v.force
    );

    if (resultFromLocal.type === "exit") {
      return;
    }

    jobbOfferStore.SET_SEARCH_TAGS_BY_PARAMS({
      params: cacheKey,
      tags: resultFromLocal,
    });

    const token = await TokenHelpers.getToken();
    const result = await getSearchTagCodesForRecruitment({
      token,
      params: v.params,
    });

    jobbOfferStore.SET_SEARCH_TAGS_BY_PARAMS({
      params: cacheKey,
      tags: {
        type: "loaded",
        value: result,
      },
    });
  }

  wantMoreTalentsTips: Record<
    string,
    ThingToLoad<WantMoreCandidatesTip[]>
  > = {};

  @Mutation
  SET_WANT_MORE_TALENTS_TIPS(v: {
    recruitmentId: string;
    tips: ThingToLoad<WantMoreCandidatesTip[]>;
  }) {
    this.wantMoreTalentsTips = {
      ...this.wantMoreTalentsTips,
      [v.recruitmentId]: v.tips,
    };
  }

  @Action
  async upsertDeclineWantMoreTip(v: { dto: UpsertDeclineWantMoreTipDto }) {
    const token = await TokenHelpers.getToken();
    await upsertDeclineWantMoreTip({
      token,
      dto: v.dto,
    });

    const tips = this.wantMoreTalentsTips[v.dto.recruitmentId] ?? {
      type: "notFetched",
    };

    if (tips.type === "loaded") {
      jobbOfferStore.SET_WANT_MORE_TALENTS_TIPS({
        recruitmentId: v.dto.recruitmentId,
        tips: {
          type: "loaded",
          value: tips.value.filter(t => !tipIsEqual(t, v.dto.tip)),
        },
      });
    }
  }

  @Action
  async loadWantMoreTalentsTips(v: { recruitmentId: string; force: boolean }) {
    const tips = this.wantMoreTalentsTips[v.recruitmentId] ?? {
      type: "notFetched",
    };

    const resultFromLocal = TokenHelpers.getThingToLoadOrExit(tips, v.force);

    if (resultFromLocal.type === "exit") {
      return;
    }

    jobbOfferStore.SET_WANT_MORE_TALENTS_TIPS({
      recruitmentId: v.recruitmentId,
      tips: resultFromLocal,
    });

    const token = await TokenHelpers.getToken();
    const result = await getWantMoreCandidatesTips({
      token,
      recruitmentId: v.recruitmentId,
    });

    jobbOfferStore.SET_WANT_MORE_TALENTS_TIPS({
      recruitmentId: v.recruitmentId,
      tips: {
        type: "loaded",
        value: result,
      },
    });
  }

  @Action
  async loadSkillRecommendationsByRoleId(v: { roleId: string; lang: string }) {
    const recommendations = this.skillRecommendationsByRoleId[v.roleId] ?? {
      type: "notFetched",
    };

    const resultFromLocal = TokenHelpers.getThingToLoadOrExit(
      recommendations,
      false
    );

    if (resultFromLocal.type === "exit") {
      return;
    }

    jobbOfferStore.SET_SKILL_RECOMMENDATIONS_BY_ROLE_ID({
      roleId: v.roleId,
      recommendations: resultFromLocal,
    });

    const token = await TokenHelpers.getToken();

    const loadedResult = await TalentProfileService.getSkillRecommendations(
      v.roleId,
      v.lang,
      token
    );

    jobbOfferStore.loadSkills({
      skillIds: loadedResult.map(r => r.skillId),
    });

    jobbOfferStore.SET_SKILL_RECOMMENDATIONS_BY_ROLE_ID({
      roleId: v.roleId,
      recommendations: {
        type: "loaded",
        value: loadedResult,
      },
    });
  }

  loadedTalentProfilesByTalentId: Record<
    string,
    LoadedTalentProfileListItem
  > = {};

  get lanesFromDb(): TrelloBoardLaneBase[] {
    return this.laneIdsFromDb.reduce((acc: TrelloBoardLaneBase[], lid) => {
      const lane = lanesStore.items.find(l => l.id === lid);
      if (lane) {
        return [
          ...acc,
          {
            id: lane.id,
            text: lane.text,
          },
        ];
      }
      return acc;
    }, []);
  }

  laneIdsFromDb: string[] = [];
  recruitmentTalentsUnmapped: TrelloBoardTalent[] = [];
  mustHaveIds: string[] = [];
  opportunityMustHaveIds: string[] = [];

  recruitmentFilters: RecruitmentFilterItem[] = [
    {
      type: "Status",
      statuses: ["Active"],
    },
  ];

  get selectedFilterOption(): RecruitmentFilterOptions | null {
    const filters = this.recruitmentFilters.filter(
      f => f.type !== "TitleContains"
    );

    const showActive =
      filters.filter(x => !FilterHelpers.getRecruitmentFilterIsEmpty(x))
        .length === 0 &&
      filters.every(f => {
        return (
          f.type === "Status" &&
          f.statuses.length === 1 &&
          f.statuses.every(x => x === "Active")
        );
      });

    if (showActive) {
      return "ActiveRecruitments";
    }
    const showClosed =
      filters.filter(x => !FilterHelpers.getRecruitmentFilterIsEmpty(x))
        .length === 1 &&
      filters.every(f => {
        return (
          f.type === "Status" &&
          f.statuses.length === 1 &&
          f.statuses.every(x => x === "Removed")
        );
      });

    if (showClosed) {
      return "ClosedRecruitments";
    }

    const showMyRecruitments =
      filters.filter(x => !FilterHelpers.getRecruitmentFilterIsEmpty(x))
        .length === 1 &&
      filters.some(f => {
        return (
          f.type === "Users" &&
          f.users.length === 1 &&
          !!f.users[0] &&
          f.users[0].type === "User" &&
          f.users[0].userId === authStore.userId
        );
      }) &&
      filters.some(f => {
        return (
          f.type === "Status" &&
          f.statuses.length === 1 &&
          f.statuses.every(x => x === "Active")
        );
      });

    if (showMyRecruitments) {
      return "MyRecruitments";
    }

    const showActiveRobots =
      filters.filter(x => !FilterHelpers.getRecruitmentFilterIsEmpty(x))
        .length === 1 &&
      filters[0]?.type === "RobotStatus" &&
      filters[0]?.statuses[0] === "Active";

    if (showActiveRobots) {
      return "ActiveRobots";
    }

    return null;
  }

  @Mutation
  SET_RECRUITMENT_FILTERS(v: RecruitmentFilterItem[]) {
    this.recruitmentFilters = v;
  }

  recruitmentsSortBy: RecruitmentSortOptions = "CreatedDate";

  @Mutation
  SET_RECRUITMENTS_SORT_BY(v: RecruitmentSortOptions) {
    this.recruitmentsSortBy = v;
  }

  filterIsOpen = false;

  @Mutation
  SET_FILTER_IS_OPEN(v: boolean) {
    this.filterIsOpen = v;
  }

  recruitmentList: RecruitmentListItem[] = [];

  get recruitmentListFiltered(): RecruitmentListItem[] {
    return SortHelper.sortItemsBy(
      this.recruitmentList
        // .filter(
        //   r =>
        //     this.recruitmentFilters.some(f => f.type === "Status") ||
        //     !r.closedDate
        // )
        .filter(
          r =>
            FilterHelpers.getRecruitmentMatchesFilters({
              filters: this.recruitmentFilters,
              rli: r,
              userIdsByRecId: this.userIdsByRecruitmentId,
            })
          // !this.recruitmentFilters.length ||
          // this.recruitmentFilters.every(f =>
          //   FilterHelpers.getRecruitmentMatchesFilter({
          //     filter: f,
          //     recruitment: r,
          //   })
          // )
        ),
      FilterHelpers.getSortFunction(this.recruitmentsSortBy, officesStore.items)
    );
  }

  get sortedRecruitmentList(): RecruitmentListItem[] {
    return SortHelper.sortItemsBy(this.recruitmentList, [
      {
        sortBy: x => x.createdDate,
        desc: true,
      },
    ]);
  }

  showEndedRecruitments = false;
  recruitmentListLoaded = false;
  teamComments: TeamComment[] = [];
  logs: RecruitmentLogObject[] = [];
  openingTalentId: string | null = null;
  trelloBoardRowVersionIsOutOfSyncId: string | null = null;
  latestRecruitmentPageId: string | null = null;
  trelloBoardRowVersion: number | null = null;
  companyMembersAreLoaded = false;
  talentAnonymous: TalentAnonymous | null = null;
  talentEvents: RecruitmentLogObject[] = [];
  teamLoadedForId: string | null = null;
  isLoadingRecruitmentLogs = false;
  loadedBaseInfoId: string | null = null;
  loadedOpportunitySourcing: string | null = null;
  trelloBoardActiveTab: TrelloBoardActiveTabType =
    TrelloBoardActiveTabType.Active;

  get trelloBoardBackgroundColor(): string {
    switch (this.trelloBoardActiveTab) {
      case TrelloBoardActiveTabType.Active:
        return "white";

      case TrelloBoardActiveTabType.Removed:
        return "#9e9e9e";

      case TrelloBoardActiveTabType.Declined:
        return "#9e9e9e";
    }
  }

  sharedTalent: GetSharedTalentDto | null = null;
  talentsToCompareTalentIds: string[] = [];

  hasCreatedFromCopy = false;

  @Mutation
  SET_HAS_CREATED_FROM_COPY(v: boolean) {
    this.hasCreatedFromCopy = v;
  }

  get talentsToCompare(): (TalentProfileToView | TalentAnonymous)[] {
    return this.talentsToCompareTalentIds.reduce(
      (acc: (TalentProfileToView | TalentAnonymous)[], tid) => {
        const tp = this.loadedTalentProfilesByTalentId[tid];

        if (tp && tp?.type === "Loaded") {
          return [...acc, tp.talentProfile];
        }

        return acc;
      },
      []
    );
  }

  @Mutation
  SET_TALENTS_TO_COMPARE_TALENT_IDS(v: string[]) {
    this.talentsToCompareTalentIds = [...new Set(v)];
  }

  @Mutation
  SET_COMPARE_FILTERED_SKILLS(v: string[]) {
    this.compareFilteredSkills = v;
  }

  @Mutation
  SET_COMPARE_FILTERED_TASK_IDS(v: string[]) {
    this.compareFilteredTaskIds = v;
  }

  @Mutation
  SET_COMPARE_START_INDEX(v: number) {
    this.compareStartIndex = v;
  }

  @Mutation
  SET_COMPARE_OPENED_PANELS(v: number[]) {
    this.compareOpenedPanels = v;
  }

  @Mutation
  SET_COMPARE_SHOW_DIFFERENCES(v: boolean) {
    this.compareShowDifferences = v;
  }

  @Mutation
  SET_SHARED_TALENT(v: GetSharedTalentDto | null) {
    this.sharedTalent = v;
  }

  @Mutation
  SET_REQUIREMENT_GROUP_IDS(v: RequirementGroupDto[]) {
    this.requirementGroupIds = v;
  }

  @Mutation
  SET_SHOW_ENDED_RECRUITMENTS(v: boolean) {
    this.showEndedRecruitments = v;
  }

  @Mutation
  SET_OPENING_TALENT_ID(v: string | null) {
    this.openingTalentId = v;
  }

  @Mutation
  SET_LOGS(v: RecruitmentLogObject[]) {
    this.logs = v;
  }

  @Mutation
  SET_TEAM_COMMENTS(comments: TeamComment[]) {
    this.teamComments = comments;
  }

  @Mutation
  SET_RECRUITMENT_LIST_LOADED(loaded: boolean) {
    this.recruitmentListLoaded = loaded;
  }

  @Mutation
  SET_RECRUITMENT_LIST(list: RecruitmentListItem[]) {
    this.recruitmentList = list;
  }

  get recruitmentIdsByTalentId(): { [key: string]: string[] } {
    return this.recruitmentList.reduce(
      (acc: { [key: string]: string[] }, r) => {
        r.savedTalentIds.forEach(tid => {
          acc[tid] = [...(acc[tid] || []), r.id];
        });
        return acc;
      },
      {}
    );
  }

  @Mutation
  SET_RECRUITMENT_TALENTS(talents: TrelloBoardTalent[]) {
    this.recruitmentTalentsUnmapped = talents;
  }

  @Mutation
  SET_TRELLO_ROW_VERSION_OUT_OF_SYNC(recruitmentId: string | null) {
    this.trelloBoardRowVersionIsOutOfSyncId = recruitmentId;
  }

  @Mutation
  SET_LATEST_RECRUITMENT_PAGE_ID(id: string | null) {
    this.latestRecruitmentPageId = id;
  }

  loadingComparePageInfo: boolean = false;

  @Mutation
  SET_LOADING_COMPARE_PAGE_INFO(v: boolean) {
    this.loadingComparePageInfo = v;
  }

  loadedRecTalentsForId: string | null = null;

  @Mutation
  SET_LOADED_REC_TALENTS_FOR_ID(v: string | null) {
    this.loadedRecTalentsForId = v;
  }

  @Mutation
  SET_TRELLO_BOARD_ACTIVE_TAB(v: TrelloBoardActiveTabType) {
    this.trelloBoardActiveTab = v;
  }

  @Mutation
  SET_TRELLO_BOARD_LANES(lanes: string[]) {
    this.laneIdsFromDb = lanes;
  }

  @Mutation
  SET_TRELLO_BOARD_ROW_VERSION(rowVersion: number) {
    this.trelloBoardRowVersion = rowVersion;
  }

  @Mutation
  SET_BASE_INFO(obj: { baseInfo: JobbOfferBaseInfo; id: string } | null) {
    this.baseInfo = obj ? obj.baseInfo : null;
    this.id = obj ? obj.id : null;
    this.loadedBaseInfoId = obj ? obj.id : null;
  }

  @Mutation
  SET_JOB_OFFER_REQUIREMENTS_PROFILE(reqs: JobOfferRequirementProfileToAdd) {
    this.jobbOfferRequirementsCurrent = reqs;
  }

  @Mutation
  SET_OPPORTUNITY_SOURCING(v: JobOfferRequirementProfileToAdd) {
    this.opportunitySourcing = v;
  }

  @Mutation
  SET_MUST_HAVE_IDS(ids: string[]) {
    this.mustHaveIds = ids;
  }

  @Mutation
  SET_OPPORTUNITY_MUST_HAVE_IDS(ids: string[]) {
    this.opportunityMustHaveIds = ids;
  }

  @Mutation
  SET_COMPANY_TEAM_MEMBERS(members: RecruitmentTeamMember[]) {
    this.companyMembers = members;
  }

  @Mutation
  SET_REQUIREMENTS_LOADED(recruitmentId: string) {
    this.requirementsLoaded = recruitmentId;
  }

  @Mutation
  SET_COMPANY_MEMBERS_LOADED(v: boolean) {
    this.companyMembersAreLoaded = v;
  }

  @Mutation
  SET_TALENT_EVENTS(events: RecruitmentLogObject[]) {
    this.talentEvents = events;
  }

  @Mutation
  SET_TEAM_LOADED_FOR_ID(v: string | null) {
    this.teamLoadedForId = v;
  }

  @Mutation
  SET_IS_LOADING_RECRUITMENT_LOGS(v: boolean) {
    this.isLoadingRecruitmentLogs = v;
  }

  @Mutation
  SET_LOADED_BASE_INFO_ID(v: string | null) {
    this.loadedBaseInfoId = v;
  }

  @Mutation
  SET_LOADED_OPPORTUNITY_SOURCING(v: string | null) {
    this.loadedOpportunitySourcing = v;
  }

  teamCommentsLoadedForRecruitmentId: string | null = null;
  @Mutation
  SET_TEAM_COMMENTS_LOADED(v: string | null) {
    this.teamCommentsLoadedForRecruitmentId = v;
  }

  chatMessagesByTalentConversationKey: Record<string, string | undefined> = {};

  @Mutation
  SET_CHAT_MESSAGES_BY_CONVERSATION_KEY(v: Record<string, string | undefined>) {
    this.chatMessagesByTalentConversationKey = v;
  }

  externalFormDatas: {
    recruitmentId: string;
    formId: string;
  }[] = [];

  @Mutation
  SET_EXTERNAL_FORM_DATAS(
    v: {
      recruitmentId: string;
      formId: string;
    }[]
  ) {
    this.externalFormDatas = v;
  }

  externalFormDatasAreLoaded = false;

  @Mutation
  SET_EXTERNAL_FORM_DATAS_LOADED(v: boolean) {
    this.externalFormDatasAreLoaded = v;
  }

  @Action
  async loadExternalFormDatas() {
    if (!this.externalFormDatasAreLoaded) {
      const token = await TokenHelpers.getToken();
      const formDatas = await jobOfferService.getExternalFormDatasByCompanyId({
        token,
      });

      jobbOfferStore.SET_EXTERNAL_FORM_DATAS(formDatas);
      jobbOfferStore.SET_EXTERNAL_FORM_DATAS_LOADED(true);
    }
  }

  @Action
  async loadTeamComments(recruitmentId: string) {
    jobbOfferStore.SET_TEAM_COMMENTS([]);
    const token = await TokenHelpers.getToken();

    await jobbOfferStore.loadCompanyTeamMembers();

    const comments = await jobOfferService.getTeamComments(
      recruitmentId,
      token
    );

    jobbOfferStore.SET_TEAM_COMMENTS(
      comments
        .map(c => {
          return {
            ...c,
            sharedToEmails: c.sharedToEmails ?? [],
            sender: FilterHelpers.getCommentSender({
              c,
              companyCommentSenders: this.companyCommentSenders,
            }),
          };
        })
        .filter(c => !!c.sender)
    );

    jobbOfferStore.SET_TEAM_COMMENTS_LOADED(recruitmentId);
  }

  loadedRecTalentsRecord: Record<string, LoadedRecTalentListItem> = {};

  get loadedRecTalents(): { [key: string]: TrelloBoardTalent } {
    return Object.values(this.loadedRecTalentsRecord).reduce(
      (acc: { [key: string]: TrelloBoardTalent }, t) => {
        if (t.type === "Loading") {
          return acc;
        }
        acc[TalentPoolHelpers.getRecTalentKey(t)] = t.recTalent;
        return acc;
      },
      {}
    );
  }

  @Mutation
  SET_LOADED_REC_TALENTS(v: LoadedRecTalentListItem[]) {
    const hej = v.reduce(
      (acc: { [key: string]: LoadedRecTalentListItem }, t) => {
        acc[TalentPoolHelpers.getRecTalentKey(t)] = t;
        return acc;
      },
      {}
    );
    this.loadedRecTalentsRecord = {
      ...this.loadedRecTalentsRecord,
      ...hej,
    };
  }

  loadingLoadedRecTalents = false;

  @Mutation
  SET_LOADING_LOADED_REC_TALENT(v: boolean) {
    this.loadingLoadedRecTalents = v;
  }

  @Action
  async loadOtherRecruitments(v: {
    recruitmentIds: string[];
    talentId: string;
  }) {
    const recruitmentIdsToLoad = v.recruitmentIds.filter(recId => {
      return !this.loadedRecTalents[
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: recId,
          talentId: v.talentId,
        })
      ];
    });
    if (!recruitmentIdsToLoad.length) {
      return;
    }

    this.SET_LOADING_LOADED_REC_TALENT(true);
    jobbOfferStore.SET_LOADED_REC_TALENTS(
      recruitmentIdsToLoad.map(rid => ({
        type: "Loading",
        recruitmentId: rid,
        talentId: v.talentId,
      }))
    );

    await jobbOfferStore.loadLocations({
      lang: $i18n.locale,
    });
    const token = await TokenHelpers.getToken();
    const recTalents = await jobOfferService.getRecruitmentTalentsByRecruitmentIds(
      {
        recruitmentIds: recruitmentIdsToLoad,
        talentId: v.talentId,
        accessToken: token,
        locations: jobbOfferStore.locations,
      }
    );

    jobbOfferStore.SET_LOADED_REC_TALENTS(
      recTalents.map(rt => ({
        type: "Loaded",
        recruitmentId: rt.recruitmentId,
        recTalent: rt,
        talentId: rt.talentId,
      }))
    );
    this.SET_LOADING_LOADED_REC_TALENT(false);
  }

  @Action
  async loadRecruitmentList(reload?: boolean) {
    if (reload || !this.recruitmentListLoaded) {
      const token = await TokenHelpers.getToken();
      const list = await jobOfferService.getRecruitmentsForList(token);
      jobbOfferStore.SET_RECRUITMENT_LIST(list);
      jobbOfferStore.SET_RECRUITMENT_LIST_LOADED(true);
    }
  }

  @Action
  async MoveTalentInLane(obj: {
    recruitmentId: string;
    id: string;
    newIndex: number;
    oldIndex: number;
    oldArray: TrelloBoardTalent[];
  }) {
    const talentsCopy = [...this.recruitmentTalentsUnmapped];

    const newArray = SortHelper.arrayMove(
      obj.oldArray,
      obj.oldIndex,
      obj.newIndex
    ).map((t, i) => ({ ...t, sortOrder: i }));

    jobbOfferStore.updateRecruitmentTalents({
      talents: this.recruitmentTalentsUnmapped.map(t => {
        const newTalent = newArray.find(newt => newt.talentId === t.talentId);
        return newTalent || t;
      }),
      recruitmentId: obj.recruitmentId,
    });

    try {
      const token = await TokenHelpers.getToken();
      const newRowVersion = await jobOfferService.updateTalentCards(
        {
          recruitmentId: obj.recruitmentId,
          rowVersion: this.trelloBoardRowVersion!,
          movement: newArray.map(t => ({
            sortOrder: t.sortOrder,
            talentId: t.talentId,
            laneId: t.laneId,
          })),
        },
        token
      );

      this.context.commit("SET_TRELLO_BOARD_ROW_VERSION", newRowVersion);
    } catch (e) {
      jobbOfferStore.updateRecruitmentTalents({
        talents: talentsCopy,
        recruitmentId: obj.recruitmentId,
      });
      jobbOfferStore.SET_TRELLO_ROW_VERSION_OUT_OF_SYNC(obj.recruitmentId);
    }
  }

  @Action
  async updateRecruitmentsAndLoadedRecTalents(v: {
    talents: TrelloBoardTalent[];
    recruitmentId: string;
  }) {
    jobbOfferStore.SET_LOADED_REC_TALENTS(
      v.talents.map(rt => ({
        type: "Loaded",
        recruitmentId: rt.recruitmentId,
        talentId: rt.talentId,
        recTalent: rt,
      }))
    );

    await lanesStore.loadLibraryLanes({
      lang: $i18n.locale,
    });

    const hiredLaneIds = lanesStore.items
      .filter(l => l.laneType === LaneType.Hired)
      .map(x => x.id);
    const activeTalents = v.talents.filter(
      t =>
        !!t.accepted &&
        !t.removed &&
        !FilterHelpers.hasDeclined(t) &&
        !!t.invited &&
        (!t.laneId || !hiredLaneIds.includes(t.laneId))
    );

    const numberOfActiveTalents = activeTalents.length;
    const numberOfAcceptedTalents = activeTalents.filter(
      t => t.isHandledDate === null
    ).length;
    const numberOfSaved = v.talents.filter(
      t => !t.invited && !t.removed && !t.declined && !t.accepted && !!t.saved
    ).length;

    const numberOfActiveOpportunityTalent = v.talents.filter(
      t =>
        !!t.accepted &&
        !t.invited &&
        !FilterHelpers.hasDeclined(t) &&
        !t.removed
    ).length;

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            numberOfSavedTalents: numberOfSaved,
            numberOfAcceptedTalents,
            numberOfActiveTalents,
            numberOfActiveOpportunityTalent,
            numberOfTalentsNotHandled: v.talents.filter(
              t => t.daysLeftDto.level === "Level3"
            ).length,
            savedTalentIds: v.talents
              .filter(t => !!t.saved && (!t.removed || !!t.accepted))
              .map(t => t.talentId),
          };
        }
        return r;
      })
    );
  }

  @Action
  async updateRecruitmentTalents(v: {
    talents: TrelloBoardTalent[];
    recruitmentId: string;
  }) {
    jobbOfferStore.SET_RECRUITMENT_TALENTS(v.talents);

    await jobbOfferStore.updateRecruitmentsAndLoadedRecTalents({
      recruitmentId: v.recruitmentId,
      talents: v.talents,
    });
  }

  @Action
  async updateRecruitmentsTalent(talent: {
    talentId: string;
    newIndex: number;
    laneId: string | null;
    recruitmentId: string;
  }) {
    const talentsCopy = [...this.recruitmentTalentsUnmapped];

    const oldTalent = this.recruitmentTalentsUnmapped.find(
      t => t.talentId === talent.talentId
    );

    if (oldTalent) {
      const newTalents = this.recruitmentTalentsUnmapped.map(t => {
        if (t.talentId === talent.talentId) {
          const movedToNewLane = t.laneId !== talent.laneId;
          return <TrelloBoardTalent>{
            ...t,
            sortOrder: talent.newIndex,
            laneId: talent.laneId,
            lastUpdatedDate: new Date(),
            isHandledDate: movedToNewLane ? new Date() : t.isHandledDate,
            statusText: movedToNewLane ? null : t.statusText,
            daysLeftDto: movedToNewLane
              ? {
                  level: "Level0",
                }
              : t.daysLeftDto,
          };
        }
        return {
          ...t,
          sortOrder:
            t.laneId === talent.laneId && t.sortOrder >= talent.newIndex
              ? t.sortOrder + 1
              : t.laneId === oldTalent.laneId &&
                t.sortOrder > oldTalent.sortOrder
              ? t.sortOrder - 1
              : t.sortOrder,
        };
      });

      const updatedTalents = newTalents.filter(t => {
        const oldTalent = talentsCopy.find(rt => rt.talentId === t.talentId);
        if (oldTalent) {
          return (
            oldTalent.sortOrder !== t.sortOrder || oldTalent.laneId !== t.laneId
          );
        }
        return false;
      });

      jobbOfferStore.updateRecruitmentTalents({
        talents: newTalents,
        recruitmentId: talent.recruitmentId,
      });

      try {
        const token = await TokenHelpers.getToken();
        const newRowVersion = await jobOfferService.updateTalentCards(
          {
            movement: updatedTalents.map(t => ({
              sortOrder: t.sortOrder,
              talentId: t.talentId,
              laneId: t.laneId,
            })),
            recruitmentId: talent.recruitmentId,
            rowVersion: this.trelloBoardRowVersion!,
          },
          token
        );

        this.context.commit("SET_TRELLO_BOARD_ROW_VERSION", newRowVersion);
      } catch (e) {
        jobbOfferStore.updateRecruitmentTalents({
          talents: talentsCopy,
          recruitmentId: talent.recruitmentId,
        });
        jobbOfferStore.SET_TRELLO_ROW_VERSION_OUT_OF_SYNC(talent.recruitmentId);
      }

      // save to db

      // if error from db. Move back again
    }
  }

  @Action
  removeTalentFromRecruitment(v: { talentId: string; recruitmentId: string }) {
    if (!this.recruitmentTalentsUnmapped.some(x => x.talentId === v.talentId)) {
      jobbOfferStore.updateRecruitmentTalents({
        talents: [
          ...this.recruitmentTalentsUnmapped,
          {
            id: null,
            accepted: null,
            contacted: null,
            declineReasonIds: [],
            declined: null,
            invited: null,
            laneId: null,
            lastUpdatedDate: new Date(),
            name: this.newTalentName,
            numberOfComments: 0,
            otherReason: null,
            rating: null,
            removed: new Date(),
            sortOrder: -1,
            talentId: v.talentId,
            saved: new Date(),
            percent: null,
            recruitmentId: v.recruitmentId,
            expectations: null,
            messageLang: null,
            invitedBySourcingRobot: false,
            daysLeftDto: {
              level: "Level0",
            },
            isHandledDate: null,
            statusText: null,
            isHired: false,
          },
        ],
        recruitmentId: v.recruitmentId,
      });
    } else {
      jobbOfferStore.updateRecruitmentTalents({
        talents: this.recruitmentTalentsUnmapped.map(t => {
          if (t.talentId === v.talentId) {
            return {
              ...t,
              removed: new Date(),
              saved: t.saved || new Date(),
              daysLeftDto: {
                level: "Level0",
              },
            };
          }
          return t;
        }),
        recruitmentId: v.recruitmentId,
      });
    }
  }

  @Action
  async rejectTalent(v: {
    talentId: string;
    recruitmentId: string;
    rejectMessage: string | null;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.removeTalentFromRecruitment({
      accessToken: token,
      message: v.rejectMessage,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
    });

    jobbOfferStore.removeTalentFromRecruitment({
      talentId: v.talentId,
      recruitmentId: v.recruitmentId,
    });
  }

  @Action
  async retakeTalent({
    talentId,
    recruitmentId,
  }: {
    talentId: string;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await retakeTalent({
      token,
      recruitmentId,
      talentId,
    });

    jobbOfferStore.updateRecruitmentTalents({
      talents: this.recruitmentTalentsUnmapped.map(t => {
        if (t.talentId === talentId) {
          return {
            ...t,
            removed: null,
          };
        }
        return t;
      }),
      recruitmentId,
    });
  }

  @Action
  async declineTalent(v: { talentId: string; recruitmentId: string }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.declineTalentFromRecruitment({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
    });

    jobbOfferStore.updateRecruitmentTalents({
      talents: this.recruitmentTalentsUnmapped.map(t => {
        if (t.talentId === v.talentId) {
          return {
            ...t,
            declined: new Date(),
            saved: t.saved || new Date(),
          };
        }
        return t;
      }),
      recruitmentId: v.recruitmentId,
    });
  }

  loadedRecruitmentLanesForId: string | null = null;

  @Mutation
  SET_LOADED_RECRUITMENT_LANES_FOR_ID(v: string | null) {
    this.loadedRecruitmentLanesForId = v;
  }

  @Action
  async loadRecruitmentLanes(v: { recruitmentId: string; lang: string }) {
    await lanesStore.loadLibraryLanes({
      lang: v.lang,
    });
    if (this.loadedRecruitmentLanesForId !== v.recruitmentId) {
      const token = await TokenHelpers.getToken();

      const lanes = await jobOfferService.getTrelloBoardLanes(
        v.recruitmentId,
        token,
        v.lang
      );
      jobbOfferStore.SET_TRELLO_BOARD_LANES(lanes);
      jobbOfferStore.SET_LOADED_RECRUITMENT_LANES_FOR_ID(v.recruitmentId);
    }
  }

  locations: ListObject[] = [];
  locationsAreLoaded = false;

  @Mutation
  SET_LOCATIONS(v: ListObject[]) {
    this.locations = v;
  }

  @Mutation
  SET_LOCATIONS_LOADED(v: boolean) {
    this.locationsAreLoaded = v;
  }

  @Action
  async loadLocations(v: { lang: string }) {
    if (!this.locationsAreLoaded) {
      const locations = await LookupDataService.getCitiesPublic(v.lang);

      jobbOfferStore.SET_LOCATIONS(locations);
      jobbOfferStore.SET_LOCATIONS_LOADED(true);
    }
  }

  lowInviteWarnings: Record<string, true> = {};

  @Mutation
  SET_RECRUITMENT_WARNED(v: { recruitmentId: string }) {
    this.lowInviteWarnings = {
      ...this.lowInviteWarnings,
      [v.recruitmentId]: true,
    };
  }

  @Action
  async loadRecruitmentTalents(v: { recruitmentId: string }) {
    if (this.loadedRecTalentsForId !== v.recruitmentId) {
      const token = await TokenHelpers.getToken();
      const accessToken = token;
      const talentsResponse = await jobOfferService.getTrelloboardTalents(
        v.recruitmentId,
        accessToken
      );

      await jobbOfferStore.loadLocations({
        lang: $i18n.locale,
      });

      jobbOfferStore.SET_TRELLO_BOARD_ACTIVE_TAB(
        TrelloBoardActiveTabType.Active
      );

      jobbOfferStore.SET_LOADED_REC_TALENTS_FOR_ID(v.recruitmentId);

      await jobbOfferStore.updateRecruitmentTalents({
        talents: talentsResponse.talents.map(x =>
          TalentHelpers.mapFromServerDto(x, jobbOfferStore.locations)
        ),
        recruitmentId: v.recruitmentId,
      });

      jobbOfferStore.SET_TRELLO_BOARD_ROW_VERSION(talentsResponse.rowVersion);

      jobbOfferStore.SET_TRELLO_ROW_VERSION_OUT_OF_SYNC(null);
    }
  }

  @Action
  async inviteTalentToRecruitment(obj: {
    talentId: string;
    recruitmentId: string;
    inviteAsRobot?: boolean;
  }) {
    jobbOfferStore.SET_IS_SAVING_OR_INVITING_TALENT(true);
    const token = await TokenHelpers.getToken();
    if (obj.inviteAsRobot) {
      await jobOfferService.inviteTalentAsSourcingRobot({
        accessToken: token,
        recruitmentId: obj.recruitmentId,
        talentId: obj.talentId,
      });
    } else {
      await jobOfferService.inviteTalentToRecruitment(
        obj.talentId,
        obj.recruitmentId,
        token
      );
    }

    notificationsStore.setSuccessMessage(
      $i18n.t("Kandidaten är nu inbjuden!").toString()
    );

    const recTalent = jobbOfferStore.recruitmentTalentsUnmapped.find(
      x => x.talentId === obj.talentId
    );

    const talentName =
      (recTalent || { name: null }).name || jobbOfferStore.newTalentName;

    let talentProfileOrNull:
      | TalentProfileToView
      | TalentAnonymous
      | null = null;
    const loadedTalentProfile = this.loadedTalentProfilesByTalentId[
      obj.talentId
    ];
    if (loadedTalentProfile && loadedTalentProfile.type === "Loaded") {
      talentProfileOrNull = loadedTalentProfile.talentProfile;
    } else {
      jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
        {
          type: "Loading",
          talentId: obj.talentId,
        },
      ]);
      const talentProfileResponse = await jobOfferService.getTalentProfileById({
        accessToken: token,
        lang: $i18n.locale,
        talentId: obj.talentId,
      });

      jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
        {
          type: "Loaded",
          talentId: obj.talentId,
          talentProfile: talentProfileResponse.talentProfile,
        },
      ]);

      talentProfileOrNull = talentProfileResponse.talentProfile;
    }

    const talentProfile = talentProfileOrNull;

    if (
      !this.recruitmentTalentsUnmapped.find(t => t.talentId === obj.talentId)
    ) {
      const newTalent: TrelloBoardTalent = {
        id: null,
        talentId: obj.talentId,
        invited: new Date(),
        accepted: null,
        declined: null,
        removed: null,
        laneId: null,
        lastUpdatedDate: new Date(),
        name: talentName,
        numberOfComments: 0,
        rating: null,
        sortOrder: -1,
        declineReasonIds: [],
        otherReason: null,
        contacted: null,
        saved: new Date(),
        percent: null,
        recruitmentId: obj.recruitmentId,
        expectations: talentProfile.expectations,
        messageLang: null,
        invitedBySourcingRobot: obj.inviteAsRobot ?? false,
        daysLeftDto: {
          level: "Level0",
        },
        isHandledDate: null,
        statusText: null,
        isHired: false,
      };

      jobbOfferStore.updateRecruitmentTalents({
        talents: [newTalent, ...this.recruitmentTalentsUnmapped],
        recruitmentId: obj.recruitmentId,
      });
    } else {
      jobbOfferStore.updateRecruitmentTalents({
        talents: [
          ...this.recruitmentTalentsUnmapped.map(t => {
            if (t.talentId === obj.talentId) {
              return {
                ...t,
                invited: new Date(),
                saved: t.saved || new Date(),
                declined: null,
                laneId: null,
                contacted: null,
                declineReasonIds: [],
                otherReason: null,
                sortOrder: FilterHelpers.hasDeclined(t) ? t.sortOrder : -1,
                expectations: talentProfile.expectations,
                invitedBySourcingRobot: obj.inviteAsRobot ?? false,
              };
            }
            return t;
          }),
        ],
        recruitmentId: obj.recruitmentId,
      });
    }

    jobbOfferStore.SET_IS_SAVING_OR_INVITING_TALENT(false);
  }

  isSavingOrInvitingTalent = false;

  @Mutation
  SET_IS_SAVING_OR_INVITING_TALENT(v: boolean) {
    this.isSavingOrInvitingTalent = v;
  }

  @Action
  async updateStatusTextOnRecTalent(v: {
    talentId: string;
    recruitmentId: string;
    statusText: string | null;
  }) {
    const token = await TokenHelpers.getToken();
    await RecruitmentTalentService.updateStatusTextOnRecTalent({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      statusText: v.statusText,
      talentId: v.talentId,
    });

    const recTalent = this.recruitmentTalentsUnmapped.find(
      x => x.recruitmentId === v.recruitmentId && x.talentId === v.talentId
    );

    if (recTalent) {
      jobbOfferStore.SET_RECRUITMENT_TALENTS(
        SignalRHelper.getListWithNewItem({
          items: this.recruitmentTalentsUnmapped,
          getId: x => `${x.talentId}|${x.recruitmentId}`,
          newItem: {
            ...recTalent,
            statusText: v.statusText,
            daysLeftDto: {
              level: "Level0",
            },
            isHandledDate: new Date(),
          },
        })
      );
    }

    await jobbOfferStore.updateRecruitmentsAndLoadedRecTalents({
      talents: this.recruitmentTalentsUnmapped,
      recruitmentId: v.recruitmentId,
    });
  }

  @Action
  async setRecTalentHandled(v: { talentId: string; recruitmentId: string }) {
    const token = await TokenHelpers.getToken();
    await RecruitmentTalentService.setRecTalentHandled({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
    });

    const recTalent = this.recruitmentTalentsUnmapped.find(
      x => x.recruitmentId === v.recruitmentId && x.talentId === v.talentId
    );

    if (recTalent) {
      jobbOfferStore.SET_RECRUITMENT_TALENTS(
        SignalRHelper.getListWithNewItem({
          items: this.recruitmentTalentsUnmapped,
          getId: x => `${x.talentId}|${x.recruitmentId}`,
          newItem: {
            ...recTalent,
            daysLeftDto: {
              level: "Level0",
            },
            isHandledDate: new Date(),
          },
        })
      );
    }

    await jobbOfferStore.updateRecruitmentsAndLoadedRecTalents({
      talents: this.recruitmentTalentsUnmapped,
      recruitmentId: v.recruitmentId,
    });
  }

  @Action
  async saveTalentToRecruitment(obj: {
    talentId: string;
    recruitmentId: string;
  }) {
    jobbOfferStore.SET_IS_SAVING_OR_INVITING_TALENT(true);
    const token = await TokenHelpers.getToken();
    await jobOfferService.saveTalentToRecruitment(
      obj.talentId,
      obj.recruitmentId,
      token
    );

    const existingRecTalent = this.recruitmentTalentsUnmapped.find(
      t => t.talentId === obj.talentId
    );
    let talentProfileOrNull:
      | TalentProfileToView
      | TalentAnonymous
      | null = null;
    const loadedTalentProfile = this.loadedTalentProfilesByTalentId[
      obj.talentId
    ];
    if (loadedTalentProfile && loadedTalentProfile.type === "Loaded") {
      talentProfileOrNull = loadedTalentProfile.talentProfile;
    } else {
      jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
        {
          type: "Loading",
          talentId: obj.talentId,
        },
      ]);
      const talentProfileResponse = await jobOfferService.getTalentProfileById({
        accessToken: token,
        lang: $i18n.locale,
        talentId: obj.talentId,
      });

      jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
        {
          type: "Loaded",
          talentId: obj.talentId,
          talentProfile: talentProfileResponse.talentProfile,
        },
      ]);

      talentProfileOrNull = talentProfileResponse.talentProfile;
    }

    const talentProfile = talentProfileOrNull;

    const newTalent: TrelloBoardTalent = {
      id: null,
      talentId: obj.talentId,
      accepted: null,
      declined: null,
      invited: null,
      removed: null,
      laneId: null,
      lastUpdatedDate: new Date(),
      name: jobbOfferStore.newTalentName,
      numberOfComments: 0,
      rating: null,
      sortOrder: -1,
      declineReasonIds: [],
      otherReason: null,
      contacted: null,
      saved: new Date(),
      percent: null,
      recruitmentId: obj.recruitmentId,
      expectations: talentProfile.expectations,
      messageLang: null,
      invitedBySourcingRobot: false,
      daysLeftDto: {
        level: "Level0",
      },
      isHandledDate: null,
      statusText: null,
      isHired: false,
    };

    const recTalent = existingRecTalent
      ? {
          ...TalentHelpers.getReusedRecTalent(existingRecTalent),
          saved: existingRecTalent.saved ?? new Date(),
          expectations: talentProfile.expectations,
        }
      : newTalent;

    if (jobbOfferStore.loadedRecTalentsForId === obj.recruitmentId) {
      const newTalents = SignalRHelper.getListWithNewItem({
        items: this.recruitmentTalentsUnmapped,
        getId: t => t.talentId,
        newItem: recTalent,
        addInBeginningOfArray: true,
      });
      await jobbOfferStore.updateRecruitmentTalents({
        talents: newTalents,
        recruitmentId: obj.recruitmentId,
      });
    } else {
      const talentsResponse = await jobOfferService.getTrelloboardTalents(
        obj.recruitmentId,
        token
      );

      await jobbOfferStore.loadLocations({
        lang: $i18n.locale,
      });

      const talentsForRecruitment = SignalRHelper.getListWithNewItem({
        items: talentsResponse.talents.map(rt =>
          TalentHelpers.mapFromServerDto(rt, jobbOfferStore.locations)
        ),
        getId: x => x.talentId,
        newItem: {
          ...newTalent,
          name:
            talentsResponse.talents.find(
              t => t.recruitmentTalent.talentId === obj.talentId
            )?.recruitmentTalent.name ??
            (talentsResponse.talents.length + 1).toString(),
        },
        addInBeginningOfArray: true,
      });

      await jobbOfferStore.updateRecruitmentsAndLoadedRecTalents({
        talents: talentsForRecruitment,
        recruitmentId: obj.recruitmentId,
      });
    }

    jobbOfferStore.SET_IS_SAVING_OR_INVITING_TALENT(false);
  }

  @Action
  async createJobbOffer(v: { baseInfo: JobbOfferBaseInfo }): Promise<string> {
    const token = await TokenHelpers.getToken();
    const recListItemDto = await jobOfferService.createRecruitment(
      {
        baseInfo: v.baseInfo,
      },
      token
    );
    jobbOfferStore.SET_BASE_INFO({
      baseInfo: v.baseInfo,
      id: recListItemDto.id,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST([
      ...this.recruitmentList,
      {
        createdDate: new Date(),
        startedDate: null,
        closedDate: null,
        id: recListItemDto.id,
        imageUrl: null,
        numberOfAcceptedTalents: 0,
        numberOfSavedTalents: 0,
        title: v.baseInfo.title.text,
        numberOfActiveOpportunityTalent: 0,
        numberOfActiveTalents: 0,
        offices: v.baseInfo.offices.map(o => ({
          id: o.id,
          latitude: o.latitude,
          longitude: o.longitude,
        })),
        opportunityActive: false,
        departmentId: null,
        teamTailorJobId: null,
        managerCoworkerId: null,
        includeInterview: false,
        visualCoworkersIds: [],
        savedTalentIds: [],
        includesTravel: v.baseInfo.includesTravel ?? false,
        roleId: v.baseInfo.roleId,
        rolesResponsibilities: v.baseInfo.rolesResponsibilities,
        workFromHome: v.baseInfo.workFromHome ?? WorkFromHome.No,
        recTalentIsOpened: null,
        acceptsFullRemote: v.baseInfo.acceptsFullRemote ?? false,
        numberOfTalentsNotHandled: 0,
        oldRobotIsActive: null,
        newRobotIsActive: null,
        robotIsBreaked: null,
        manualRobotIsActive: null,
        pricesByOfficeId: recListItemDto.pricesByOfficeId ?? {},
      },
    ]);

    if (authStore.userId) {
      jobbOfferStore.ADD_RECRUITMENT_TO_USER({
        recruitmentId: recListItemDto.id,
        userId: authStore.userId,
      });
    }

    return recListItemDto.id;
  }

  openedRecruitmentIds: string[] = [];

  @Mutation
  SET_RECRUITMENT_ID_OPENED(recId: string) {
    this.openedRecruitmentIds = [...this.openedRecruitmentIds, recId];
  }

  @Action
  async setIsOpenedOnRecruitment(v: { recruitmentId: string }) {
    const recListItem = this.recruitmentList.find(
      x => x.id === v.recruitmentId
    );

    if (!recListItem?.recTalentIsOpened) {
      const token = await TokenHelpers.getToken();
      await jobOfferService.updateRecruitmentRecTalentIsOpened({
        accessToken: token,
        recruitmentId: v.recruitmentId,
      });

      jobbOfferStore.SET_RECRUITMENT_LIST(
        this.recruitmentList.map(li => {
          if (li.id === v.recruitmentId) {
            return {
              ...li,
              recTalentIsOpened: li.recTalentIsOpened ?? new Date(),
            };
          }
          return li;
        })
      );
    }
  }

  @Action
  removeTalentToCompare(v: { talentId: string }) {
    jobbOfferStore.SET_TALENTS_TO_COMPARE_TALENT_IDS(
      this.talentsToCompareTalentIds.filter(tid => tid !== v.talentId)
    );
  }

  teamTailorJobs: ListObject[] = [];
  teamTailorJobsLoaded = false;

  @Mutation
  SET_TEAM_TAILOR_JOBS(v: ListObject[]) {
    this.teamTailorJobs = v;
  }

  @Mutation
  SET_TEAM_TAILOR_JOBS_LOADED(v: boolean) {
    this.teamTailorJobsLoaded = v;
  }

  @Action
  async loadTeamTailorJobs() {
    if (this.teamTailorJobsLoaded) {
      return;
    }
    const token = await TokenHelpers.getToken();

    const jobs = await jobOfferService.getTeamTailorJobs({
      token,
    });

    jobbOfferStore.SET_TEAM_TAILOR_JOBS(jobs);

    jobbOfferStore.SET_TEAM_TAILOR_JOBS_LOADED(true);
  }

  @Action
  async addTalentToTeamTailor(v: {
    talentId: string;
    recruitmentId: string;
    teamTailorJobId: string | null;
  }) {
    const token = await TokenHelpers.getToken();

    const ttId = await jobOfferService.addTalentToTeamTailor({
      token,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
      teamTailorJobId: v.teamTailorJobId,
    });

    const oldItem = companyBaseStore.teamTailorTalentIds.find(
      x => x.talentId === v.talentId
    );

    const newList = SignalRHelper.getListWithNewItem({
      items: companyBaseStore.teamTailorTalentIds,
      newItem: {
        talentId: v.talentId,
        teamTailorId: ttId,
        recruitmentIds: [...(oldItem?.recruitmentIds ?? []), v.recruitmentId],
      },
      getId: x => x.talentId,
    });

    companyBaseStore.SET_TEAM_TAILOR_TALENT_IDS(newList);
  }

  jobylonJobs: ThingToLoad<ListObject[]> = {
    type: "notFetched",
  };

  @Mutation
  SET_JOBYLON_JOBS(v: ThingToLoad<ListObject[]>) {
    this.jobylonJobs = v;
  }

  @Action
  async loadJobylonJobs(v: { force?: boolean } = {}) {
    const ttl = TokenHelpers.getThingToLoadOrExit(
      this.jobylonJobs,
      v.force ?? false
    );

    if (ttl.type === "exit") {
      return;
    }

    jobbOfferStore.SET_JOBYLON_JOBS(ttl);

    const token = await TokenHelpers.getToken();

    const jobs = await jobOfferService.getJobylonJobs({
      token,
    });

    jobbOfferStore.SET_JOBYLON_JOBS({
      type: "loaded",
      value: jobs,
    });
  }

  @Action
  async addTalentToJobylon(v: {
    talentId: string;
    recruitmentId: string;
    jobylonJobId: string;
  }) {
    const token = await TokenHelpers.getToken();

    const jobylonApplicationId = await jobOfferService.addTalentToJobylon({
      token,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
      jobylonJobId: v.jobylonJobId,
    });

    const companyTalent = companyBaseStore.companyTalents.find(
      ct => ct.talentId === v.talentId
    );

    if (!companyTalent) {
      return;
    }

    companyBaseStore.SET_COMPANY_TALENTS({
      type: "loaded",
      value: companyBaseStore.companyTalents.map(ct => {
        if (ct.talentId === v.talentId) {
          return {
            ...ct,
            jobylonObject: {
              jobylonApplicationId,
              addedFromRecruitmentIds: [v.recruitmentId],
            },
          };
        }
        return ct;
      }),
    });
  }

  @Action
  async updateTeamTailorJobId(v: {
    recruitmentId: string;
    teamTailorJobId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.updateTeamTailorJobId({
      token,
      recruitmentId: v.recruitmentId,
      teamTailorJobId: v.teamTailorJobId,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            teamTailorJobId: v.teamTailorJobId,
          };
        }
        return r;
      })
    );
  }

  skillsById: Map<string, LangObject | Promise<LangObject>> = new Map<
    string,
    LangObject | Promise<LangObject>
  >();

  @Mutation
  SET_SKILLS_BY_ID(v: { id: string; obj: LangObject | Promise<LangObject> }[]) {
    const newMap = new Map<string, LangObject | Promise<LangObject>>();
    Array.from(this.skillsById.keys()).forEach(skillId => {
      const newValue =
        v.find(x => x.id === skillId)?.obj ?? this.skillsById.get(skillId);
      if (newValue !== undefined) {
        newMap.set(skillId, newValue);
      }
    });
    v.forEach(x => {
      newMap.set(x.id, x.obj);
    });
    this.skillsById = newMap;
  }

  @Action
  async loadSkills(v: { skillIds: string[] }) {
    const idsToLoad = v.skillIds.filter(sid => {
      return !this.skillsById.has(sid);
    });

    if (idsToLoad.length) {
      const promise = TokenHelpers.getToken().then(token => {
        return jobOfferService.getSkillsByIds({
          skillIds: idsToLoad,
          token,
        });
      });

      if (idsToLoad.length) {
        jobbOfferStore.SET_SKILLS_BY_ID(
          idsToLoad.map(skillId => {
            const hej: Promise<LangObject> = promise.then(
              x =>
                x.find(o => o.id === skillId) ?? {
                  id: skillId,
                  textDict: {},
                }
            );
            return {
              id: skillId,
              obj: hej,
            };
          })
        );
      }

      const skills = await promise;

      jobbOfferStore.SET_SKILLS_BY_ID(
        skills.map(x => ({
          id: x.id,
          obj: x,
        }))
      );
    }
  }

  schooldsById: Map<string, ListObject> = new Map<string, ListObject>();

  @Mutation
  SET_SCHOOLS_BY_ID(v: ListObject[]) {
    this.schooldsById = [
      ...Array.from(this.schooldsById.values()),
      ...v,
    ].reduce((acc: Map<string, ListObject>, x) => {
      acc.set(x.id, x);
      return acc;
    }, new Map<string, ListObject>());
  }

  @Action
  async loadSchools(v: { schoolIds: string[] }) {
    const idsToLoad = v.schoolIds.filter(sid => {
      return !this.schooldsById.has(sid);
    });

    if (idsToLoad.length) {
      jobbOfferStore.SET_SCHOOLS_BY_ID(
        idsToLoad.map(x => ({ id: x, text: "..." }))
      );
      const token = await TokenHelpers.getToken();

      const schools = await jobOfferService.getSchoolsByIds({
        schoolIds: idsToLoad,
        token,
      });

      jobbOfferStore.SET_SCHOOLS_BY_ID(schools);
    }
  }

  @Action
  async anonymizeTalent(v: {
    talentId: string;
    recruitmentId: string;
    removeCash: boolean;
  }) {
    const token = await TokenHelpers.getToken();
    await jobOfferService.anonymizeTalent({
      accessToken: token,
      recruitmentId: v.recruitmentId,
      talentId: v.talentId,
    });

    jobbOfferStore.updateRecruitmentTalents({
      talents: jobbOfferStore.recruitmentTalentsUnmapped.map(rt => {
        if (
          rt.talentId === v.talentId &&
          rt.recruitmentId === v.recruitmentId
        ) {
          return {
            ...rt,
            name: "Anonymized",
          };
        }

        return rt;
      }),
      recruitmentId: v.recruitmentId,
    });

    jobbOfferStore.loadTalentProfileById({
      talentId: v.talentId,
      force: true,
    });

    talentPoolStore.loadMessagesByRecTalent({
      talentId: v.talentId,
      recruitmentId: v.recruitmentId,
      force: true,
    });

    if (v.removeCash) {
      jobbOfferStore.loadLogsForTalent({
        talentId: v.talentId,
        recruitmentId: v.recruitmentId,
      });
      jobbOfferStore.loadCommentsOnTalent({
        talentId: v.talentId,
        recruitmentId: v.recruitmentId,
      });
    }
  }

  @Action
  async loadTalentToCompare(obj: { talentId: string }) {
    jobbOfferStore.SET_TALENTS_TO_COMPARE_TALENT_IDS([
      ...this.talentsToCompareTalentIds,
      obj.talentId,
    ]);
    if (this.loadedTalentProfilesByTalentId[obj.talentId]) {
      return;
    }
    jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
      {
        type: "Loading",
        talentId: obj.talentId,
      },
    ]);
    const token = await TokenHelpers.getToken();
    const talentProfileResponse = await jobOfferService.getTalentProfileById({
      accessToken: token,
      lang: $i18n.locale,
      talentId: obj.talentId,
    });

    const profile = talentProfileResponse.talentProfile;

    jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
      {
        type: "Loaded",
        talentId: obj.talentId,
        talentProfile: profile,
      },
    ]);

    jobbOfferStore.SET_TALENTS_TO_COMPARE_TALENT_IDS([
      ...this.talentsToCompareTalentIds,
      obj.talentId,
    ]);
  }

  @Action
  async loadTalentsToCompare(obj: { talentIds: string[] }) {
    jobbOfferStore.SET_TALENTS_TO_COMPARE_TALENT_IDS(obj.talentIds);
    const talentIdsToFetch = obj.talentIds.filter(tid => {
      const loadedTalent = this.loadedTalentProfilesByTalentId[tid];
      return !loadedTalent;
    });
    jobbOfferStore.ADD_LOADED_TALENT_PROFILES(
      talentIdsToFetch.map(talentId => ({
        type: "Loading",
        talentId,
      }))
    );
    const token = await TokenHelpers.getToken();
    const profileResponses = await Promise.all(
      talentIdsToFetch.map(talentId => {
        return jobOfferService.getTalentProfileById({
          accessToken: token,
          lang: $i18n.locale,
          talentId,
        });
      })
    );

    jobbOfferStore.ADD_LOADED_TALENT_PROFILES(
      profileResponses.map(x => ({
        type: "Loaded",
        talentId: x.talentProfile.id,
        talentProfile: x.talentProfile,
      }))
    );
  }

  @Action
  async removeCompanyTeamMember(v: { email: string }) {
    const token = await TokenHelpers.getToken();

    const user = this.companyMembers.find(m => m.email === v.email);
    if (!user) {
      throw new Error("User could not be found!");
    }

    await jobOfferService.updateCompanyTeamMember({
      accessToken: token,
      user: { ...user, isRemoved: true },
    });

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
      this.companyMembers.map(m => {
        if (m.email === v.email) {
          return { ...m, isRemoved: true };
        }
        return m;
      })
    );
  }

  @Action
  async unremoveCompanyTeamMember(v: { email: string }) {
    const token = await TokenHelpers.getToken();

    const user = this.companyMembers.find(m => m.email === v.email);
    if (!user) {
      throw new Error("User could not be found!");
    }

    await jobOfferService.updateCompanyTeamMember({
      accessToken: token,
      user: { ...user, isRemoved: false },
    });

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
      this.companyMembers.map(m => {
        if (m.email === v.email) {
          return { ...m, isRemoved: false };
        }
        return m;
      })
    );
  }

  @Action
  async updateCompanyTeamMember(v: { user: RecruitmentTeamMember }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.updateCompanyTeamMember({
      accessToken: token,
      user: v.user,
    });

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
      this.companyMembers.map(m => {
        if (m.email === v.user.email) {
          return v.user;
        }
        return m;
      })
    );
  }

  @Action
  async endRecruitment(v: {
    recruitmentId: string;
    messageToActiveTalents: string | null;
    lang: string;
  }) {
    const token = await TokenHelpers.getToken();
    await jobOfferService.endJobOffer({
      message: v.messageToActiveTalents,
      recruitmentId: v.recruitmentId,
      token,
    });

    await lanesStore.loadLibraryLanes({
      lang: v.lang,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            closedDate: new Date(),
            newRobotIsActive: false,
            oldRobotIsActive: false,
            manualRobotIsActive: false,
          };
        }
        return r;
      })
    );
  }

  @Action
  async addCompanyTeamMember(v: { requestDto: InviteUserRequestDto }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.addCompanyTeamMember({
      accessToken: token,
      requestDto: v.requestDto,
    });

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS([
      ...this.companyMembers,
      {
        ...v.requestDto.user,
        userId: null,
        firstName: null,
        lastName: null,
        invitedByUserId: authStore.userId,
      },
    ]);
  }

  @Action
  async resendUserInvite(v: { email: string }) {
    const token = await $msal.acquireToken();

    if (typeof token !== "string") {
      throw new TypeError("Ett fel inträffade!");
    }

    await jobOfferService.resendUserInvite({
      accessToken: token,
      email: v.email,
    });
  }

  @Action
  async changeEmailOnCompanyUser(v: { oldEmail: string; newEmail: string }) {
    const token = await $msal.acquireToken();

    if (typeof token !== "string") {
      throw new TypeError("Ett fel inträffade!");
    }

    await jobOfferService.changeEmailOnCompanyUser({
      accessToken: token,
      oldEmail: v.oldEmail,
      newEmail: v.newEmail,
    });

    jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(
      this.companyMembers.map(m => {
        if (m.email === v.oldEmail) {
          return {
            ...m,
            email: v.newEmail,
          };
        }
        return m;
      })
    );
  }

  @Action
  async loadCompanyTeamMembers() {
    if (!this.companyMembersAreLoaded) {
      const token = await TokenHelpers.getToken();
      const companyTeamMembers = await jobOfferService.getCompanyTeamMembers(
        token
      );

      jobbOfferStore.SET_COMPANY_MEMBERS_LOADED(true);

      jobbOfferStore.SET_COMPANY_TEAM_MEMBERS(companyTeamMembers);
    }
  }

  @Action
  async UpdateRatingOnTalent(v: {
    talentId: string;
    recruitmentId: string;
    rating: number | null;
  }) {
    const token = await TokenHelpers.getToken();
    try {
      await jobOfferService.updateRatingOnTalent({
        accessToken: token,
        rating: v.rating,
        recruitmentId: v.recruitmentId,
        talentId: v.talentId,
      });

      jobbOfferStore.updateRecruitmentTalents({
        talents: this.recruitmentTalentsUnmapped.map(t => {
          if (t.talentId === v.talentId) {
            return {
              ...t,
              rating: v.rating,
            };
          }
          return t;
        }),
        recruitmentId: v.recruitmentId,
      });
    } catch (error) {
      notificationsStore.setErrorMessage({
        error,
      });
    }
  }

  @Action
  async addMessageToTalent(obj: {
    message: string;
    talentId: string;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    const newId = await jobOfferService.addMessageToTalent(
      obj.message,
      obj.talentId,
      obj.recruitmentId,
      token
    );

    await jobbOfferStore.loadCompanyTeamMembers();

    const senderMember =
      jobbOfferStore.companyCommentSenders[authStore.userId!];

    if (senderMember) {
      const newMessage: TalentMessage = {
        comment: obj.message,
        date: new Date(),
        fromTalent: false,
        id: newId,
        sender: senderMember,
      };

      const conversation = talentPoolStore.messagesByRecTalentMap.get(
        TalentPoolHelpers.getRecTalentKey({
          recruitmentId: obj.recruitmentId,
          talentId: obj.talentId,
        })
      );

      if (conversation?.type === "Loaded") {
        talentPoolStore.SET_MESSAGE_BY_REC_TALENT(
          SignalRHelper.getListWithNewItem({
            items: talentPoolStore.messagesByRecTalent,
            getId: x => TalentPoolHelpers.getRecTalentKey(x),
            newItem: {
              type: "Loaded",
              comments: SignalRHelper.getListWithNewItem({
                items: conversation.comments,
                getId: x => x.id,
                newItem: newMessage,
              }),
              recruitmentId: obj.recruitmentId,
              talentId: obj.talentId,
            },
          })
        );
      }

      jobbOfferStore.updateRecruitmentTalents({
        talents: this.recruitmentTalents.map(t => {
          if (t.talentId === obj.talentId) {
            return {
              ...t,
              contacted: new Date(),
              isHandledDate: new Date(),
              daysLeftDto: {
                level: "Level0",
              },
            };
          }
          return t;
        }),
        recruitmentId: obj.recruitmentId,
      });
    }
  }

  showWantMoreTalentTips = true;

  @Mutation
  SET_SHOW_WANT_MORE_TALENT_TIPS(v: boolean) {
    this.showWantMoreTalentTips = v;
  }

  showSummaryOnTalentProfile = true;

  @Mutation
  SET_SHOW_SUMMARY_ON_TALENT_PROFILE(v: boolean) {
    this.showSummaryOnTalentProfile = v;
  }

  showCheckboxesOnMatchingExperienceItems = true;

  @Mutation
  SET_SHOW_CHECKBOXES_ON_MATCHING_EXPERIENCE_ITEMS(v: boolean) {
    this.showCheckboxesOnMatchingExperienceItems = v;
  }

  loadingLogsForTalent = false;

  @Mutation
  SET_LOADING_LOGS_FOR_TALENT(v: boolean) {
    this.loadingLogsForTalent = v;
  }

  @Action
  async loadLogsForTalent(obj: { talentId: string; recruitmentId: string }) {
    jobbOfferStore.SET_TALENT_EVENTS([]);
    jobbOfferStore.SET_LOADING_LOGS_FOR_TALENT(true);
    const token = await TokenHelpers.getToken();

    const logs = await jobOfferService.getLogsForTalent(
      obj.recruitmentId,
      token,
      obj.talentId
    );

    jobbOfferStore.SET_TALENT_EVENTS(logs);
    jobbOfferStore.SET_LOADING_LOGS_FOR_TALENT(false);
  }

  @Mutation
  ADD_LOADED_TALENT_PROFILES(
    v: (LoadedTalentProfileListItem & {
      talentId: string;
    })[]
  ) {
    v.forEach(tp => {
      this.loadedTalentProfilesByTalentId = {
        ...this.loadedTalentProfilesByTalentId,
        [tp.talentId]: tp,
      };
    });
  }

  @Action
  async loadTalentProfileById(obj: {
    talentId: string;
    force?: boolean;
  }): Promise<void> {
    const existing = this.loadedTalentProfilesByTalentId[obj.talentId];
    if (!obj.force && existing) {
      return;
    }
    jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
      {
        ...obj,
        type: "Loading",
      },
    ]);
    const token = await TokenHelpers.getToken();
    const profileResponse = await jobOfferService.getTalentProfileById({
      accessToken: token,
      lang: $i18n.locale,
      talentId: obj.talentId,
    });

    const profile = profileResponse.talentProfile;

    jobbOfferStore.ADD_LOADED_TALENT_PROFILES([
      {
        ...obj,
        type: "Loaded",
        talentProfile: profile,
      },
    ]);
  }

  @Action
  async addTeamComment(obj: {
    recruitmentId: string;
    comment: string;
    mentionIds: string[];
  }) {
    await jobbOfferStore.loadCompanyTeamMembers();

    const senderMember =
      jobbOfferStore.companyCommentSenders[authStore.userId!];

    await jobbOfferStore.loadRecruitmentList();

    const title = jobbOfferStore.recruitmentList.find(
      r => r.id === obj.recruitmentId
    )!.title;

    const token = await TokenHelpers.getToken();

    const newId = await jobOfferService.addTeamComment(
      obj.recruitmentId,
      obj.comment,
      obj.mentionIds,
      title,
      token
    );

    this.context.commit("SET_TEAM_COMMENTS", [
      ...this.teamComments,
      {
        id: newId,
        comment: obj.comment,
        date: new Date(),
        sender: senderMember,
      },
    ] as TeamComment[]);
  }

  @Action
  async addTeamCommentOnTalent(obj: {
    newComment: string;
    mentionIds: string[];
    talentId: string;
    recruitmentId: string;
    talentName: string;
  }) {
    await jobbOfferStore.loadCompanyTeamMembers();

    await jobbOfferStore.loadJobOfferBaseInfo(obj.recruitmentId);

    const title = jobbOfferStore.baseInfo!.title.text;

    const token = await TokenHelpers.getToken();
    const commentId = await jobOfferService.addTeamCommentOnTalent(
      obj.recruitmentId,
      obj.newComment,
      obj.talentId,
      obj.mentionIds,
      title,
      obj.talentName,
      token
    );

    const senderMember =
      jobbOfferStore.companyCommentSenders[authStore.userId!];

    if (senderMember) {
      const comment: TeamComment = {
        comment: obj.newComment,
        date: new Date(),
        id: commentId,
        sender: senderMember,
        sharedToEmails: [],
      };

      const existing = talentPoolStore.teamCommentsByRecTalentMap.get(
        TalentPoolHelpers.getRecTalentKey(obj)
      );

      talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: talentPoolStore.teamCommentsByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loaded",
            comments: SignalRHelper.getListWithNewItem({
              items: existing?.type === "Loaded" ? existing.comments : [],
              getId: x => x.id,
              newItem: comment,
            }),
            recruitmentId: obj.recruitmentId,
            talentId: obj.talentId,
          },
        })
      );
      jobbOfferStore.updateRecruitmentTalents({
        talents: this.recruitmentTalentsUnmapped.map(t => {
          if (t.talentId !== obj.talentId) {
            return t;
          }
          return {
            ...t,
            numberOfComments: (t.numberOfComments || 0) + 1,
          };
        }),
        recruitmentId: obj.recruitmentId,
      });
    }
  }

  @Action
  async loadCommentsOnTalent(obj: { talentId: string; recruitmentId: string }) {
    const existing = talentPoolStore.teamCommentsByRecTalentMap.get(
      TalentPoolHelpers.getRecTalentKey(obj)
    );

    if (existing) {
      return;
    }

    talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
      SignalRHelper.getListWithNewItem({
        items: talentPoolStore.teamCommentsByRecTalent,
        getId: x => TalentPoolHelpers.getRecTalentKey(x),
        newItem: {
          type: "Loading",
          recruitmentId: obj.recruitmentId,
          talentId: obj.talentId,
        },
      })
    );

    const token = await TokenHelpers.getToken();

    const comments = await jobOfferService.getTeamCommentsOnTalent(
      obj.recruitmentId,
      obj.talentId,
      token
    );

    await jobbOfferStore.loadCompanyTeamMembers();

    talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
      SignalRHelper.getListWithNewItem({
        items: talentPoolStore.teamCommentsByRecTalent,
        getId: x => TalentPoolHelpers.getRecTalentKey(x),
        newItem: {
          type: "Loaded",
          comments: comments
            .map(c => {
              return {
                ...c,
                sharedToEmails: c.sharedToEmails ?? [],
                sender: FilterHelpers.getCommentSender({
                  c,
                  companyCommentSenders: jobbOfferStore.companyCommentSenders,
                }),
              };
            })
            .filter(c => !!c.sender),
          recruitmentId: obj.recruitmentId,
          talentId: obj.talentId,
        },
      })
    );
  }

  @Mutation
  ADD_RECRUITMENT_TO_USER(v: { userId: string; recruitmentId: string }) {
    this.companyMembers = this.companyMembers.map(m => {
      if (m.userId === v.userId) {
        return {
          ...m,
          recruitmentIds: [...new Set([...m.recruitmentIds, v.recruitmentId])],
        };
      }
      return m;
    });
  }

  @Mutation
  REMOVE_RECRUITMENT_FROM_USER(v: { userId: string; recruitmentId: string }) {
    this.companyMembers = this.companyMembers.map(m => {
      if (m.userId === v.userId) {
        return {
          ...m,
          recruitmentIds: m.recruitmentIds.filter(r => r !== v.recruitmentId),
        };
      }
      return m;
    });
  }

  @Action
  async addTeamMemberToRecruitment(v: {
    memberId: string;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.addRecruitmentTeamMember(
      v.recruitmentId,
      v.memberId,
      token
    );

    jobbOfferStore.ADD_RECRUITMENT_TO_USER({
      recruitmentId: v.recruitmentId,
      userId: v.memberId,
    });
  }

  @Action
  async removeTeamMemberFromRecruitment(v: {
    memberId: string;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.deleteRecruitmentTeamMember(
      v.recruitmentId,
      v.memberId,
      token
    );

    jobbOfferStore.REMOVE_RECRUITMENT_FROM_USER({
      recruitmentId: v.recruitmentId,
      userId: v.memberId,
    });
  }

  @Action
  async loadRecritmentLogs(jobOfferId: string) {
    jobbOfferStore.SET_IS_LOADING_RECRUITMENT_LOGS(true);
    const token = await TokenHelpers.getToken();
    const logs: RecruitmentLogObject[] = await jobOfferService.getLogs(
      jobOfferId,
      token
    );
    jobbOfferStore.SET_IS_LOADING_RECRUITMENT_LOGS(false);
    jobbOfferStore.SET_LOGS(logs);
  }

  @Action
  async loadSharedTalent(v: { token: string; lang: string }) {
    const response = await jobOfferService.getSharedTalent({
      token: v.token,
      lang: v.lang,
    });

    jobbOfferStore.SET_SHARED_TALENT(response);
  }

  @Action
  async shareTalent(v: {
    talentId: string;
    recruitmentId: string;
    emails: string[];
    talentName: string;
    titleName: string;
    message: string;
  }) {
    const token = await TokenHelpers.getToken();

    await jobOfferService.shareTalent({
      ...v,
      token,
    });
  }

  @Action
  async addCommentOnSharedTalent(v: { token: string; comment: string }) {
    await jobOfferService.postCommentOnSharedTalent({
      comment: v.comment,
      token: v.token,
    });
  }

  @Action
  async setRecruitmentStarted(v: {
    recruitmentId: string;
    activateRobot: { type: "Yes"; manual: boolean } | { type: "No" };
  }) {
    const accessToken = await TokenHelpers.getToken();

    await jobOfferService.setRecruitmentStarted({
      recruitmentId: v.recruitmentId,
      activateRobot: v.activateRobot,
      accessToken,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            startedDate: new Date(),
          };
        }
        return r;
      })
    );
  }

  @Action
  async setHasReachedRecapDate(v: { recruitmentId: string }) {
    const token = await TokenHelpers.getToken();

    const recListItem = this.recruitmentList.find(
      r => r.id === v.recruitmentId
    );

    if (recListItem?.hasReachedRecapDate) {
      return;
    }

    await setHasReachedRecapDate({
      recruitmentId: v.recruitmentId,
      token,
    });

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            hasReachedRecapDate: r.hasReachedRecapDate ?? new Date(),
          };
        }
        return r;
      })
    );
  }

  @Action
  async updateBaseInfo(v: {
    baseInfo: JobbOfferBaseInfo;
    recruitmentId: string;
  }): Promise<boolean> {
    const token = await TokenHelpers.getToken();
    await jobOfferService.updateJobOfferBaseInfo(
      v.baseInfo,
      v.recruitmentId,
      token
    );

    jobbOfferStore.SET_RECRUITMENT_LIST(
      this.recruitmentList.map(r => {
        if (r.id === v.recruitmentId) {
          return {
            ...r,
            title: v.baseInfo.title.text,
            offices: v.baseInfo.offices.map(o => ({
              id: o.id,
              latitude: o.latitude,
              longitude: o.longitude,
            })),
            roleId: v.baseInfo.roleId,
            includesTravel: v.baseInfo.includesTravel ?? false,
            workFromHome: v.baseInfo.workFromHome ?? WorkFromHome.No,
            rolesResponsibilities: v.baseInfo.rolesResponsibilities,
            acceptsFullRemote:
              (v.baseInfo.workFromHome === WorkFromHome.AllWeek &&
                v.baseInfo.acceptsFullRemote) ??
              false,
          };
        }
        return r;
      })
    );

    const oldRoleId = this.baseInfo?.roleId;
    const newRoleId = v.baseInfo.roleId;

    let visualIsChanged = false;

    if (oldRoleId !== newRoleId) {
      await jobbOfferVisualStore.loadJobbOfferVisual({
        recruitmentId: v.recruitmentId,
        force: false,
      });
      if (jobbOfferVisualStore.visual.taskIds.length) {
        await jobbOfferVisualStore.updateJobbOfferVisual({
          visual: {
            ...jobbOfferVisualStore.visual,
            taskIds: [],
          },
          recruitmentId: v.recruitmentId,
        });
        visualIsChanged = true;
      }
    }

    jobbOfferStore.SET_BASE_INFO({
      baseInfo: v.baseInfo,
      id: v.recruitmentId,
    });

    return visualIsChanged;
  }

  @Action
  async updateOpportunitySourcing(v: {
    sourcing: OpportunitySourcing;
    recruitmentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    const etag = await jobOfferService.updateOpportunitySourcing({
      accessToken: token,
      opportunitySourcing: v.sourcing,
      recruitmentId: v.recruitmentId,
    });

    jobbOfferStore.SET_OPPORTUNITY_SOURCING_ACTIVATED(true);

    jobbOfferStore.SET_OPPORTUNITY_SOURCING_PAUSED(false);

    jobbOfferStore.SET_OPPORTUNITY_SOURCING({
      ...v.sourcing.sourcing,
      etag,
    });

    jobbOfferStore.SET_OPPORTUNITY_MUST_HAVE_IDS(
      v.sourcing.sourcing.requirementGroups
        .filter(r => r.isMustHave)
        .map(r => r.id)
    );
  }

  @Action
  async setOpportunityPaused(v: { recruitmentId: string }) {
    const token = await TokenHelpers.getToken();
    const sourcing = await jobOfferService.getOpportunitySourcing({
      accessToken: token,
      recruitmentId: v.recruitmentId,
    });

    if (sourcing) {
      sourcing.isPaused = true;
      await jobOfferService.updateOpportunitySourcing({
        accessToken: token,
        opportunitySourcing: sourcing,
        recruitmentId: v.recruitmentId,
      });

      jobbOfferStore.SET_OPPORTUNITY_SOURCING_PAUSED(true);
      jobbOfferStore.SET_OPPORTUNITY_SOURCING(sourcing.sourcing);
      jobbOfferStore.SET_OPPORTUNITY_MUST_HAVE_IDS(
        sourcing.sourcing.requirementGroups
          .filter(r => r.isMustHave)
          .map(r => r.id)
      );
    }
  }

  @Action
  loadJobOfferRequirementProfile(recruitmentId: string) {
    if (this.requirementsLoaded !== recruitmentId) {
      jobbOfferStore.SET_REQUIREMENTS_LOADED(recruitmentId);
      jobbOfferStore.SET_JOB_OFFER_REQUIREMENTS_PROFILE(defaultRequirements);
      jobbOfferStore.SET_MUST_HAVE_IDS([]);
    }
  }

  opportunitySourcingIsActivated = false;

  @Mutation
  SET_OPPORTUNITY_SOURCING_ACTIVATED(v: boolean) {
    this.opportunitySourcingIsActivated = v;
  }

  opportunitySourcingIsPaused = false;

  @Mutation
  SET_OPPORTUNITY_SOURCING_PAUSED(v: boolean) {
    this.opportunitySourcingIsPaused = v;
  }

  @Action
  async loadOpportunitySourcing(v: {
    recruitmentId: string;
    refresh?: boolean;
  }) {
    if (v.refresh || v.recruitmentId !== this.loadedOpportunitySourcing) {
      const token = await TokenHelpers.getToken();

      const result = await jobOfferService.getOpportunitySourcing({
        accessToken: token,
        recruitmentId: v.recruitmentId,
      });

      jobbOfferStore.SET_OPPORTUNITY_SOURCING_ACTIVATED(!!result);
      jobbOfferStore.SET_OPPORTUNITY_SOURCING_PAUSED(
        result ? result.isPaused : false
      );

      jobbOfferStore.SET_LOADED_OPPORTUNITY_SOURCING(v.recruitmentId);
      jobbOfferStore.SET_OPPORTUNITY_MUST_HAVE_IDS(
        result
          ? result.sourcing.requirementGroups.reduce(
              (acc: string[], rGroup) => {
                if (rGroup.isMustHave) {
                  return [...acc, rGroup.id];
                }
                return acc;
              },
              []
            )
          : []
      );

      jobbOfferStore.SET_OPPORTUNITY_SOURCING(
        result
          ? result.sourcing
          : {
              baseSalary: 0,
              branchIds: [],
              educationDomainIds: [],
              educationLevel: null,
              responsibilities: [],
              roleIds: [],
              schools: [],
              skills: [],
              taskIds: [],
              branches: [],
              languageIds: [],
              variableCompensation: null,
              yearsOfWorkExperience: null,
              maxYearsOfWorkExperience: null,
            }
      );
    }
  }

  @Action
  async loadJobOfferBaseInfo(recruitmentId: string) {
    if (recruitmentId !== this.loadedBaseInfoId) {
      jobbOfferStore.SET_BASE_INFO(null);
      const token = await TokenHelpers.getToken();
      const result = await jobOfferService.getJobOfferBaseInfo(
        recruitmentId,
        token
      );

      jobbOfferStore.SET_LOADED_BASE_INFO_ID(recruitmentId);

      jobbOfferStore.SET_BASE_INFO({
        baseInfo: result,
        id: recruitmentId,
      });
    }
  }

  get trelloBoardRowVersionIsOutOfSync(): boolean {
    return (
      !!this.trelloBoardRowVersionIsOutOfSyncId &&
      this.trelloBoardRowVersionIsOutOfSyncId === this.loadedRecTalentsForId
    );
  }

  get teamMembers(): (RecruitmentTeamMember & { userId: string })[] {
    return getTeamMembersForRecruitment({
      allCompanyMembers: this.companyMembers,
      recruitmentId: this.latestRecruitmentPageId,
    });
  }

  get userIdsByRecruitmentId() {
    return this.recruitmentList.reduce((acc: Record<string, string[]>, r) => {
      const apa = this.companyMembers.reduce((acc2: string[], m) => {
        if (m.recruitmentIds.includes(r.id) && m.userId !== null) {
          return [...acc2, m.userId];
        }

        return acc2;
      }, []);

      acc[r.id] = apa;

      return acc;
    }, {});
  }

  get lanes(): TrelloBoardLaneBase[] {
    const dbLanes = this.lanesFromDb.filter(
      l => !["Inkorg", "Granskning"].includes(l.text)
    );

    return [...TalentStatusHelpers.preLanes, ...dbLanes];
  }

  get newTalentName(): string {
    return (this.recruitmentTalentsUnmapped.length + 1).toString();
  }

  get recruitmentTalents(): TrelloBoardTalent[] {
    return this.recruitmentTalentsUnmapped.map(t => {
      return {
        ...t,
        laneId: TalentStatusHelpers.getLaneIdForTalent(
          t,
          this.lanes.map(l => l.id)
        ),
      };
    });
  }

  get companyCommentSenders(): { [key: string]: CommentSender } {
    return this.companyMembers.reduce(
      (acc: { [key: string]: CommentSender }, m) => {
        if (!m.userId) {
          return acc;
        }
        return {
          ...acc,
          [m.userId]: {
            firstName: m.firstName || m.email,
            lastName: m.lastName || "",
            userId: m.userId,
          },
        };
      },
      {}
    );
  }

  get companyMemberNamesById(): { [key: string]: string } {
    return jobbOfferStore.companyMembers.reduce(
      (acc: { [key: string]: string }, m) => {
        if (!m.userId) {
          return acc;
        }
        return { ...acc, [m.userId]: `${m.firstName} ${m.lastName}` };
      },
      {}
    );
  }
}
